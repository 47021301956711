// import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const BrandHook = () => {
    const data = useStaticQuery(graphql`
    {
      allContentfulBrand(sort: {fields: order, order: ASC}) {
        nodes {
          title
          link
          description {
            description
          }
          icon {
            svg {
              content
            }
          }
        }
      }
    }
  `)
    return data.allContentfulBrand.nodes
}

export default BrandHook