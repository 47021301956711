import React from "react";

const FullScreenSection = ({children,className="",sectionRef,contentRef})=>{

    // const sectionRef = useRef(null);

    return (
        <div ref={sectionRef} className={"w-screen min-h-screen " + className}>
            <div className="z-30 relative px-6 mx-auto lg:max-w-1366 lg:mx-auto lg:px-6" ref={contentRef}>
                {children}
            </div>
        </div>
    )
}

export default FullScreenSection;