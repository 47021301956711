import React,{useEffect,useRef,useState} from "react";

export default ({w=300,h=300,fill="transparent",stroke="#FFF",lineWidth=1,bgSpeed=.7}) => {

    const canvasRef = useRef(null);
    const bgRef = useRef(null);
    const [bgImage,setBgImage] = useState(null);
    const bgX= -w/2;
    const bgY = -h/2;

    useEffect(()=>{
        const updateCanvas =() => {
            if(!canvasRef.current){
                return;
            }
            const ctx = canvasRef.current.getContext('2d');

            ctx.save();

            ctx.rect(0,0, w, h);
            ctx.fillStyle=fill;
            ctx.fill();

            ctx.beginPath();
            ctx.strokeStyle=stroke;
            ctx.lineWidth = lineWidth;
            ctx.rect(lineWidth/4,lineWidth/4,w - lineWidth/4, h - lineWidth/4);
            ctx.fill();
            ctx.stroke();

            const url = canvasRef.current.toDataURL('image/png');

            setBgImage(url);
        }
        updateCanvas();
    },[fill,h,lineWidth,stroke,w])


    return (
        <div
            className={"fixed top-0 left-0 w-screen h-screen overflow-hidden z-10 pointer-events-none"}
            ref={bgRef}
            style={{
                backgroundImage:`${bgImage ? `url(${bgImage})` : 'none'}`,
                backgroundPositionX:`${bgX}px`,
                backgroundPositionY:`${bgY}px`,
            }}
        >
            <div className={"m-10 hidden"}>
                <canvas ref={canvasRef} width={ w } height={h}/>
            </div>
        </div>
    )
}