import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import LandingImageHook from "../hooks/landing-image-hook";
import useMetadata from "../hooks/use-metadata";

const SEO = () => {

    const { defaultTitle, defaultDescription, titleTemplate, siteUrl } = useMetadata();
    const { ogImage } = LandingImageHook();
    const defaultOGImage = `${siteUrl}${ogImage.childImageSharp.fixed.src}`
    return (
        <GatsbySeo
            title={defaultTitle}
            titleTemplate={titleTemplate}
            description={defaultDescription}
            siteUrl={siteUrl}
            canonical={siteUrl}
            language="zh-TW"
            openGraph={{
                url: siteUrl,
                title: defaultTitle,
                description: defaultDescription,
                images: [
                    {
                        url: `${defaultOGImage}`,
                        width: 1200,
                        height: 630,
                        alt: defaultTitle,
                    },
                ],
                site_name: defaultTitle,
                type: "website",
                locale: "zh_TW",
            }}
            // facebook={{
            //     appId: "260692155140220",
            // }}
        />
    );
};
export default SEO;