import React,{useEffect} from "react"
import Svg3dLogo from "./svg-3d-logo-new";
import { gsap } from "gsap";

const LogoWithFilter = ({moveRange=0,Top=0,Opacity,logoSize=400,logoColor="dark",containerRef,depth=100,fixed=false,LogoRef,svgRef={current:[]}})=>{

    // const LogoRef = useRef(null)
    // const svgRef = useRef([]);
    // const style = {
    //     opacity:0
    // }
    // alert(myOpacity ? "true":"false")

    useEffect(()=>{
        // console.log(LogoRef)
        if(!LogoRef.current) return;
        // console.log("effect")
        gsap.to(LogoRef.current, {left: moveRange,opacity:Opacity, duration: .5});
    })

    // return(<></>)

    return (
        <div ref={LogoRef} className={`top-0 left-0 w-screen h-screen pointer-events-none z-20 transition-all duration-300 ${fixed ? 'fixed':'absolute'}`}
             style={{
                 // opacity:Opacity,
                 top:`${Top}px`,
                 // left:`${moveRange}px`
             }}
        >
            <div
                className="absolute top-0 left-0 overflow-hidden z-20 h-screen logo-light"
                ref={containerRef}
            >
                <div className="flex w-screen h-screen items-center justify-center" >
                    <div
                        ref={el=> svgRef.current[0] = el}
                        className={`opacity-0 transition-opacity duration-300 ${logoColor !== "dark" ? 'opacity-100' : null}`}
                    >
                        <Svg3dLogo
                            width={logoSize}
                            depth={depth}
                            stroke="rgb(60,69,90)"
                            fill="rgba(255,255,255,.7)"
                        />
                    </div>
                </div>
            </div>
            <div
                className="absolute top-0 left-0 overflow-hidden z-20 h-screen logo-dark"
            >
                <div className="flex w-screen h-screen items-center justify-center" >
                    <div ref={el=> svgRef.current[1] = el}
                         className={`opacity-0 transition-opacity duration-300 ${logoColor === "dark" ? 'opacity-100' : null}`}
                    >
                        <Svg3dLogo
                            width={logoSize}
                            depth={depth}
                            stroke="#FFFFFF"
                            fill="rgba(61,65,72,.7)"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogoWithFilter;

