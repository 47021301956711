import React from "react"
import "../styles/style.css";
import "../styles/010Logo.css";

export default function Home() {

  // const [text,setText] = useState("Some Test Here")

  return (
      <div>
        Nothing found here
      </div>
  )
}
