import React from "react"
import CanvasGridBg from "../components/canvas-gird-bg"
import Img from "gatsby-image/withIEPolyfill"
import {FiX} from "react-icons/fi"
import CanvasHoverLine from "../components/canvas-hover-line"

export default ({members,show,setShow=()=>{}})=>{
    if(!members){ return  ""}

    // const {title,name,description,image} = member
    const setting = {
        className:"max-w-full max-h-full",
        objectFit:"cover",
        objectPosition:"50% 0%",
        imgStyle:{
            width:'100%',
            height:'100%'
        }
    }
    return (
        <div className={`fixed inset-0 bg-dark z-40 px-6 py-12 sm:py-24 overflow-y-auto ${show ? "opacity-100" : "opacity-0 pointer-events-none"}`}>
            <CanvasGridBg w={100} h={100} bgSpeed={.1} stroke={"rgba(158,160,163,.27)"}/>
            <div className="absolute top-0 right-0 mt-3 mr-3 cursor-pointer z-20" onClick={()=>{setShow(false)}} onKeyDown={()=>{}} role="button" tabIndex={-1}>
                <FiX className="text-white text-3xl sm:text-4xl" />
            </div>
            <div className="relative z-20 grid grid-cols-2 lg:grid-cols-4 sm:grid-cols-3 gap-x-10 pt-4 justify-between lg:max-w-1366 lg:mx-auto lg:items-center lg:h-full">
                {
                    members.slice(4).map((member,index)=>{

                        const {name,image,title} = member
                        return(
                            <div key={`single_member_${index}`} className="pb-10 sm:pb-12 w-140 sm:w-208 mx-auto lg:cursor-pointer group">
                                <div className="relative h-140 sm:h-208 lg:max-w-full lg:max-h-full">
                                    <div className=" border-white border transition-all duration-500 h-full w-full absolute top-0 right-0 -mr-1 lg:colorless lg:group-hover:colorful lg:group-hover:to-right-bottom-4">
                                        <Img fixed={image.members}  {...setting}/>
                                    </div>
                                    <div className="border transition-all duration-500 border-white h-full w-full absolute left-0 top-0 -mt-2 -ml-1 pointer-events-none lg:group-hover:to-left-top-4">
                                        <CanvasHoverLine className="absolute top-0 left-0 transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={16} h={16}/>
                                        <CanvasHoverLine className="absolute top-full left-0 transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={16} h={16}/>
                                        <CanvasHoverLine className="absolute top-full left-full transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={16} h={16}/>
                                        <CanvasHoverLine className="absolute top-0 left-full transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={16} h={16}/>
                                    </div>
                                </div>
                                <div className="text-white text-center text-base mt-3 sm:-ml-2 whitespace-no-wrap">
                                    {title} <strong>{name}</strong>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}