import React from "react"
import CanvasGridBg from "../components/canvas-gird-bg"
import OnScreenTypewriter from "../components/on-screen-typewriter"
import Img from "gatsby-image/withIEPolyfill"
import CanvasHoverLine from "../components/canvas-hover-line"

import {FiX} from "react-icons/fi"

export default ({member,setMember=()=>{}})=>{
    if(!member){ return  ""}
    const {title,name,description,image} = member
    const setting = {
        className:"max-w-full max-h-full",
        objectFit:"cover",
        objectPosition:"50% 0%",
        imgStyle:{
            width:'100%',
            height:'100%'
        }
    }
    return (
        <div className="fixed inset-0 bg-dark z-40 px-6 py-12 sm:px-24 sm:py-24 h-screen overflow-y-auto">
            <CanvasGridBg w={100} h={100} bgSpeed={.1} stroke={"rgba(158,160,163,.27)"}/>
            <div className="absolute top-0 right-0 mt-3 mr-3 cursor-pointer" onClick={()=>{setMember(null)}} onKeyDown={()=>{}} role="button" tabIndex={-1}>
                <FiX className="text-white text-3xl sm:text-4xl" />
            </div>
            <div className="relative z-20 lg:flex lg:container lg:mx-auto lg:items-center lg:h-full">
                <div className="relative w-300 h-400 mx-auto group lg:w-500 lg:h-660">
                    <div className="transition-all duration-200 border-white border absolute top-0 left-0 w-full h-full justify-center items-center flex lg:group-hover:bg-white lg:group-hover:to-right-bottom lg:relative">
                        <Img fixed={image.inner} {...setting} />
                    </div>
                    <div className="transition-all duration-200 border-white border absolute bottom-0 right-0 w-full h-full mb-3 mr-3 lg:group-hover:to-left-top">
                        <CanvasHoverLine className="absolute top-0 left-0 transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={18} h={18}/>
                        <CanvasHoverLine className="absolute top-full left-0 transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={18} h={18}/>
                        <CanvasHoverLine className="absolute top-full left-full transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={18} h={18}/>
                        <CanvasHoverLine className="absolute top-0 left-full transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={18} h={18}/>
                    </div>
                </div>
                <div className="none lg:block lg:w-1/6"></div>
                <div className="mt-6 lg:mt-0 lg:w-5/6">
                    <h3 className="text-3xl text-white hollow font-black leading-normal sm:text-72 sm:text-center lg:text-left">{title}</h3>
                    <div className="mt-1">
                        <OnScreenTypewriter Tag="h3" text={name} className="hollow leading-none text-4xl font-black text-white font-share block sm:text-96 sm:text-center lg:text-left"/>
                    </div>
                    <p className="mt-6 text-white sm:text-xl">
                        {description.description}
                    </p>
                </div>
            </div>
        </div>
    )
}