// import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const useMetadata = () => {
    const data = useStaticQuery(graphql`
    {
      site(siteMetadata: {}) {
        siteMetadata {
          defaultDescription
          defaultName
          defaultTitle
          siteUrl
          titleTemplate
        }
      }
    }
  `)
    return data.site.siteMetadata
}

export default useMetadata
