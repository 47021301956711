import React from "react";
import TrackVisibility from 'react-on-screen';
import CountUp from "react-countup";
import CanvasHoverLine from "./canvas-hover-line";

export default ({number,unit,description})=>{
    return (
        <div className="mb-12 sm:flex sm:items-center group">
            <div className="mx-3 relative flex items-center justify-center h-32 sm:h-42 sm:w-300rest sm:mr-6 lg:w-1/2">
                <div className="transition-all duration-200 absolute lg:group-hover:to-left-top top-0 left-0 w-full h-full border-white border -ml-6px -mt-3">
                    <CanvasHoverLine className="absolute top-0 left-0 transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={18} h={18}/>
                    <CanvasHoverLine className="absolute top-full left-0 transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={18} h={18}/>
                    <CanvasHoverLine className="absolute top-0 left-full transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={18} h={18}/>
                    <CanvasHoverLine className="absolute top-full left-full transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={18} h={18}/>
                </div>
                <div className="transition-all duration-200 absolute lg:group-hover:to-right-bottom lg:group-hover:bg-white top-0 left-0 w-full h-full border-white border ml-6px"/>
                <div className="transition-all duration-200 text-static font-bold text-white lg:group-hover:to-right-bottom lg:group-hover:text-bg-dark transition-all duration-200">
                    <TrackVisibility tag={"span"} once={true}>
                        {({ isVisible }) => isVisible ? <CountUp start={0} end={number} duration={1} /> : "0"}
                    </TrackVisibility>
                    {unit}
                </div>
            </div>
            <div className="sm:w-300 lg:w-1/2">
                <p className="mt-3 text-xl font-bold text-center text-white sm:mt-0 sm:text-28 sm:text-left">{description}</p>
            </div>
        </div>
    )

}