import React from "react";
import "../styles/Logo.css";

export default ({dark=false,setRef,width=400,depth=100,stroke="rgb(60,69,90)",strokeWidth=1,fill="rgba(255,255,255,.7)"})=>{
    const baseW = width * 13/90;
    const baseH = width * 11/90;
    const logoPoints01 = [
        [0,0],
        [baseW * 2,0],
        [baseW * 2,baseH],
        [baseW * 1,baseH],
        [baseW * 1,width - baseH],
        [baseW * 2,width - baseH],
        [baseW * 2,baseH],
        [baseW * 3,baseH],
        [baseW * 3,width],
        [0,width],
        [0,0],
    ];

    const pathStyle={
        style:{
            stroke,
            fill,
            strokeWidth,
        }
    }

    return (
        <div
            className="logo-3d transform-3d"
            ref={setRef}
            style={{
                width:width+ "px",
                height:width + "px",
                animation: "spinXYZ 30s infinite linear"
            }}
        >
            <div
                className="back absolute top-0 left-0 w-full h-full transform-3d"
                style={{
                    transform: `translateZ(-${depth/2}px)`
                }}
            >

                <svg className="origin-top-left" width={width} height={width}>
                    <g transform={`translate(${strokeWidth/2},${strokeWidth/2})`}>
                        <polygon points={logoPoints01} {...pathStyle}/>
                        <path
                            {...pathStyle}
                            d={`
                        M${baseW * 4} 0
                        V${width}
                        h${baseW * 3}  
                        V0
                        Z
                        M${baseW * 5} ${baseH}
                        H${baseW * 6}
                        V${width - baseH}
                        H${baseW * 5}
                        Z  
                        `}
                        />
                    </g>
                </svg>

            </div>

            <div className="front absolute top-0 left-0 w-full h-full transform-3d"
                 style={{
                     transform: `translateZ(${depth/2}px)`
                 }}
            >
                <svg className="origin-top-left" width={width } height={width }>
                    <g transform={`translate(${strokeWidth/2},${strokeWidth/2})`}>
                        <polygon points={logoPoints01} {...pathStyle}/>
                        <path
                            {...pathStyle}
                            d={`
                        M${baseW * 4} 0
                        V${width}
                        h${baseW * 3}  
                        V0
                        Z
                        M${baseW * 5} ${baseH}
                        H${baseW * 6}
                        V${width - baseH}
                        H${baseW * 5}
                        Z  
                        `}
                        />
                    </g>
                </svg>
            </div>

            <div
                className={"left absolute top-0 left-0 transform-3d"}
                style={{
                    transform: `translateZ(-${depth/2}px) rotateY(-90deg)`
                }}
            >
                <svg className={"absolute top-0 left-0 origin-top-left"}  width={depth} height={width} {...pathStyle}>
                    <rect width={depth} height={width}/>
                </svg>

                <svg className={"d1 absolute top-0 left-0"}  width={depth} height={width} {...pathStyle}
                     style={
                         {...pathStyle.style,
                             transform: `translateZ(-${baseW}px)`,
                         }
                     }
                >
                    <rect width={depth} height={width - 2 * baseH}
                          transform={`translate(0,${baseH})`}
                    />
                </svg>

                <svg className={"d2-small absolute top-0 left-0"}  width={depth} height={width} {...pathStyle}
                     style={
                         {...pathStyle.style,
                             transform: `translateZ(-${baseW*2}px)`,
                         }
                     }
                >
                    <rect width={depth} height={width - baseH}/>
                </svg>

                <svg className={"d2 absolute top-0 left-0"}  width={depth} height={width} {...pathStyle}
                     style={
                         {...pathStyle.style,
                             transform: `translateZ(-${baseW*2}px)`,
                         }
                     }
                >
                    <rect width={depth} height={width - 2 * baseH}
                          transform={`translate(0,${baseH})`}
                    />
                </svg>

                <svg className={"d3 absolute top-0 left-0"}  width={depth} height={width} {...pathStyle}
                     style={
                         {...pathStyle.style,
                             transform: `translateZ(-${baseW*3}px)`,
                         }
                     }
                >
                    <rect width={depth} height={width - 1 * baseH}
                          transform={`translate(0,${baseH})`}
                    />
                </svg>

                <svg className={"d4 absolute top-0 left-0"}  width={depth} height={width} {...pathStyle}
                     style={
                         {...pathStyle.style,
                             transform: `translateZ(-${baseW*4}px)`,
                         }
                     }
                >
                    <rect width={depth} height={width}/>
                </svg>

                <svg className={"d5 absolute top-0 left-0"}  width={depth} height={width} {...pathStyle}
                     style={
                         {...pathStyle.style,
                             transform: `translateZ(-${baseW*5}px)`,
                         }
                     }
                >
                    <rect width={depth} height={width - 2 * baseH}
                          transform={`translate(0,${baseH})`}
                    />
                </svg>

                <svg className={"d6 absolute top-0 left-0"}  width={depth} height={width} {...pathStyle}
                     style={
                         {...pathStyle.style,
                             transform: `translateZ(-${baseW*6}px)`,
                         }
                     }
                >
                    <rect width={depth} height={width - 2 * baseH}
                          transform={`translate(0,${baseH})`}
                    />
                </svg>

                <svg className={"d7 absolute top-0 left-0"}  width={depth} height={width} {...pathStyle}
                     style={
                         {...pathStyle.style,
                             transform: `translateZ(-${width}px)`,
                         }
                     }
                >
                    <rect width={depth} height={width}/>
                </svg>

            </div>

            <div
                className={"top absolute top-0 left-0 transform-3d"}
                style={{
                    transform: `translateZ(-${depth/2}px) rotateX(90deg)`
                }}
            >
                <svg className={"t1 absolute top-0 left-0"}  width={width} height={depth} {...pathStyle}
                     style={
                         {...pathStyle.style,
                             // transform: `translateZ(-${width}px)`,
                         }
                     }
                >
                    <rect width={ 2 * baseW} height={depth}/>

                    <rect width={ 3 * baseW} height={depth}
                          transform={`translate(${4*baseW},0)`}
                    />
                </svg>

                <svg className={"t2 absolute top-0 left-0"}  width={width} height={depth} {...pathStyle}
                     style={
                         {...pathStyle.style,
                             transform: `translateZ(-${baseH}px)`,
                         }
                     }
                >
                    <rect width={baseW} height={depth}
                          transform={`translate(${baseW},0)`}
                    />
                    <rect width={baseW} height={depth}
                          transform={`translate(${2*baseW},0)`}
                    />
                    <rect width={baseW} height={depth}
                          transform={`translate(${5*baseW},0)`}
                    />
                </svg>
                <svg className={"t3 absolute top-0 left-0"}  width={width} height={depth} {...pathStyle}
                     style={
                         {...pathStyle.style,
                             transform: `translateZ(-${width - baseH}px)`,
                         }
                     }
                >
                    <rect width={baseW} height={depth}
                          transform={`translate(${baseW},0)`}
                    />
                    <rect width={baseW} height={depth}
                          transform={`translate(${5*baseW},0)`}
                    />
                </svg>

                <svg className={"t4 absolute top-0 left-0"}  width={width} height={depth} {...pathStyle}
                     style={
                         {...pathStyle.style,
                             transform: `translateZ(-${width}px)`,
                         }
                     }
                >
                    <rect width={ 3 * baseW} height={depth}/>

                    <rect width={ 3 * baseW} height={depth}
                          transform={`translate(${4*baseW},0)`}
                    />
                </svg>
            </div>

        </div>
    )
}