// import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ComponentName = () => {
    const data = useStaticQuery(graphql`
    {
      ogimage:file(name: {eq: "ogimage"}, ext: {eq: ".png"}) {
        id
        childImageSharp {
          fixed(pngQuality: 10, width: 1200, height: 630) {
            src
          }
        }
      }
    }
  `)
    return {
        ogImage:data.ogimage
    }
}

export default ComponentName