import React,{useEffect,useReducer} from "react";
import CanvasHoverLine from "../components/canvas-hover-line"

export default ({title,description,Icon})=>{

    const reducer = (state, action) => {
        return  SVGfixer(Icon)
    };

    const SVGfixer =(Icon)=> {
        const reanderIcon = document.createElement("div")
        reanderIcon.innerHTML = Icon
        const targetSvg = reanderIcon.querySelector("svg")
        targetSvg.classList.add("w-full","h-auto","lg:group-hover:fill-gray","lg:transition-all-200")
        return targetSvg.outerHTML
    }

    const [fixedSvg, dispatch] = useReducer(reducer, "");

    useEffect(()=>{
        dispatch()
    })

    return (
        <div className="mb-12 sm:flex sm:items-center group cursor-pointer">

            <div className="relative w-42 h-42 mx-auto sm:ml-3">
                <div className="transition-all duration-200 border-white border absolute top-0 left-0 w-full h-full justify-center items-center flex lg:group-hover:bg-white lg:group-hover:to-right-bottom" dangerouslySetInnerHTML={{ __html: fixedSvg }} />
                <div className="transition-all duration-200 border-white border absolute bottom-0 right-0 w-full h-full mb-3 mr-3 lg:group-hover:to-left-top">
                    <CanvasHoverLine className="absolute top-0 left-0 transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={18} h={18}/>
                    <CanvasHoverLine className="absolute top-full left-0 transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={18} h={18}/>
                    <CanvasHoverLine className="absolute top-0 left-full transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={18} h={18}/>
                </div>
            </div>
            <div className="sm:ml-6 sm:flex-1">
                <h3 className="text-xl font-bold text-white text-center mt-3 sm:text-left sm:mt-0 sm:text-2xl">{title}</h3>
                <p className="text-white text-center mt-3 sm:text-left sm:text-xl">{description}</p>
            </div>
        </div>
    )
}