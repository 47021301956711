// import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ServiceHook = () => {
    const data = useStaticQuery(graphql`
    {
      allContentfulService(sort: {order: ASC, fields: order}) {
        nodes {
          title
          description {
            description
          }
          icon {
            svg {
              content
            }
          }
        }
      }
    }
  `)
    return data.allContentfulService.nodes
}

export default ServiceHook