// import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const StatisticHook = () => {
    const data = useStaticQuery(graphql`
    {
      allContentfulStatistics(sort: {fields: order, order: ASC}) {
        nodes {
          number
          title
          unit
        }
      }
    }
  `)
    return data.allContentfulStatistics.nodes
}

export default StatisticHook
