import React,{useRef,useEffect} from "react";

export default ({w=300,h=150,strokeStyle="#FFF",className,speed=1,Xfrom = "left",style})=>{
    const canvasRef = useRef(null);
    const raito = h/w;
    const fixedW = 2 * w;
    const fixedH = 2 * h;

    useEffect(()=>{

        const draw = (ctx,frameCount) =>{
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
            ctx.beginPath();

            if(Xfrom === "left"){
                ctx.moveTo(0, 0);
                ctx.lineTo(frameCount, frameCount * raito);
            }

            if(Xfrom === "right"){
                ctx.moveTo(fixedW, 0);
                ctx.lineTo( fixedW - frameCount, frameCount * raito);
            }
            ctx.lineWidth = 2;
            ctx.strokeStyle = strokeStyle;
            ctx.stroke();
        }

        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        let frameCount = 0
        let animationFrameId

        //Our draw came here
        const render = () => {
            frameCount = frameCount < fixedW ? frameCount+speed: frameCount;
            draw(context, frameCount)
            animationFrameId = window.requestAnimationFrame(render)
        }
        render()

        return () => {
            window.cancelAnimationFrame(animationFrameId)
        }
    },[fixedW,speed,Xfrom,raito,strokeStyle])

    return (
        <>
            <canvas
                className={className}
                ref={canvasRef}
                width={ fixedW }
                height={ fixedH }
                style={{
                    width : w,
                    height : h,
                    ...style
                }}
            />
        </>
    )
}