import React,{useState,useRef,useEffect} from "react"
import { gsap } from "gsap";

export default () => {
    const [submited,setSubmited] = useState(false)
    const [isSending,setIsSending] = useState(false)
    const [formData,setFormData] = useState({
        name:'',
        mail:'',
        msg:''
    })
    const msgRef = useRef(null)
    const action = "https://docs.google.com/forms/u/0/d/e/1FAIpQLSeR7jQI67q-Yz6_S_kD2tNXeA8t_EOtAqFnq8K_-hR71g-GUA/formResponse"

    const handleInputChange = (e,target) => {
        setFormData({
            ...formData,
            [target]: e.target.value
        })
    }

    const cleanFormData = () => {
        let EmptyData = {}
        Object.keys(formData).forEach((key,index)=>{
            EmptyData[key] = ""
        })

        setFormData(EmptyData)
    }

    useEffect(()=>{
        if(!msgRef.current) return;
        const tl = gsap.timeline({
            onComplete: () => {
                setSubmited(false)
                setIsSending(false)
            }
        });
        tl.to(msgRef.current, {y: 50,opacity:0, duration: .5});
        tl.to(msgRef.current, {y: 0,opacity:1, duration: 1});
        tl.to(msgRef.current, {y: -50,opacity: 0, duration: .5});
    })
    return (
        <>

            {
                submited ?
                    <div className="fixed w-screen h-screen flex items-center justify-center px-10 z-30 top-0 left-0">
                        <div ref={msgRef}  className="w-48 h-48 flex items-center justify-center bg-white text-gray-010 text-lg opacity-0">
                            您的訊息已送出
                        </div>
                    </div>
                    :
                    <iframe
                        title="contactForm"
                        name="iframe"
                        className="hidden"
                        onLoad={()=>{
                            setSubmited(true)
                            cleanFormData()
                        }}
                    />
            }

            <form
                action={action}
                target="iframe"
                method="POST"
                className={`${isSending ? 'opacity-50 pointer-events-none':'opacity-100'} transition-opacity duration-300`}
            >
                <div>
                    <label htmlFor="ip_1" className="text-xl text-white leading-normal mb-3 block">姓名</label>
                    <input
                        id="ip_1"
                        name="entry.784473096"
                        type="text"
                        className="transition-all duration-200 block w-full bg-transparent border border-input py-2 px-3 text-white focus:outline-none focus:border-white"
                        value={formData.name}
                        onChange={(e) => {handleInputChange(e,'name')}}
                        required
                    />
                </div>

                <div className="mt-6">
                    <label htmlFor="ip_2" className="text-xl text-white leading-normal mb-3 block">E-mail</label>
                    <input
                        name="entry.1267946263"
                        type="email"
                        className="transition-all duration-200 block w-full bg-transparent border border-input py-2 px-3 text-white focus:outline-none focus:border-white"
                        required
                        value={formData.mail}
                        onChange={(e) => {handleInputChange(e,'mail')}}
                    />
                </div>

                <div className="mt-6">
                    <label htmlFor="ip_3" className="text-xl text-white leading-normal mb-3 block">留言</label>
                    <textarea
                        name="entry.1161058160"
                        className="transition-all duration-200 block w-full bg-transparent border border-input py-2 px-3 text-white h-40 focus:outline-none focus:border-white"
                        required
                        value={formData.msg}
                        onChange={(e) => {handleInputChange(e,'msg')}}
                    />
                </div>

                <button
                    type="submit"
                    className="transition-all duration-200 border border-white w-20 h-12 flex justify-center items-center text-white mt-6 hover:bg-white hover:text-gray-010 focus:outline-none"
                    onClick={()=>{ setIsSending(true)}}
                >
                    送出
                </button>
            </form>
        </>

    )
}