import React from "react"
// import GetImage from "../hooks/use-image-hook"
import Img from "gatsby-image/withIEPolyfill"

export default ({imagePath,title,name,image,onClick=()=>{}})=>{
    const setting = {
        className:"max-w-full max-h-full",
        objectFit:"cover",
        objectPosition:"50% 0%",
        imgStyle:{
            width:'100%',
            height:'100%'
        }
    }

    return (
        <div className="px-4 mb-12 group" onClick={onClick} onKeyDown={()=>{}} role="button" tabIndex={-1}>
            <div className="text-gray-010 text-xl mb-10 sm:-ml-2">
                {title} <strong>{name}</strong>
            </div>
            <div className="relative w-64 h-64 sm:w-56 sm:h-56 lg:max-w-full lg:max-h-full">
                <div className="cursor-pointer transition-all duration-500 h-full w-full absolute top-0 right-0 -mr-2 lg:colorless lg:group-hover:colorful lg:group-hover:to-right-bottom-4">
                    <Img fixed={image.fixed}  {...setting}/>
                    {/*<GetImage path={imagePath} setting={setting}/>*/}
                </div>
                <div className="border transition-all duration-500 border-gray-010 h-full w-full absolute left-0 top-0 -mt-4 -ml-2 pointer-events-none lg:group-hover:to-left-top-4"/>
            </div>
        </div>
    )
}