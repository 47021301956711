import React from "react";
import TrackVisibility from 'react-on-screen';
import Typewriter from "../components/typewriter";

export default ({Tag="h2",text=" ",className="",speed=200,label=true,offset=0,once=true})=>{

    return (
        <span className={"block relative"}>
            <span className={className + " block opacity-0"}>
                <span>{text}</span>
                <span className="typewriter-dash">_</span>
            </span>
            <div className="absolute inset-0">
                <TrackVisibility once={once}>
                {
                    ({ isVisible }) => <Tag><Typewriter className={className} speed={speed} label={label} text={isVisible? text :""}></Typewriter></Tag>
                }
            </TrackVisibility>
            </div>
        </span>
    )
}