import React,{useState,useEffect,useRef} from "react"
import "../styles/style.css"
import CanvasGridBg from "../components/canvas-gird-bg"
import FullScreenSection from "../components/full-screen-section"
import LogoWithFilter from "../components/LogoWithFilter"
import OnScreenTypewriter from "../components/on-screen-typewriter"
import ServiceBlock from "../components/service-block"
import BrandBlock from "../components/brand-block"
import StaticBlock from "../components/static-block"
import MemberBlock from "../components/member-block"
import ContactForm from "../components/contact-form"
import Logo010 from "../assets/logo_010.svg"
import ServiceHook from "../hooks/service-hook";
import BrandHook from "../hooks/brand-hook";
import StatisticHook from "../hooks/statistics-hook";
import MemberHook from "../hooks/member-hook";
import SingleMemberModal from "../components/single-member-modal"
import MembersModal from "../components/members-modal"
import SEO from "../components/seo"

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


export default function Home() {

    const services = ServiceHook()
    const brands = BrandHook()
    const statistics = StatisticHook()
    const members = MemberHook()

    const [windowW,setWindowW] = useState(null)
    const [triggerOffset,setTriggerOffset] = useState(-300)
    const [logoSize,setLogoSize] = useState([200,50])
    const [currentMember,setCurrentMember] = useState(null)
    const [displayMembers,setDisplayMember] = useState(false)

    const [logoOpacity,setLogoOpacity] = useState(false)
    const [pin,setPin] = useState(false)
    const [logoColor,setLogoColor] = useState("dark")
    const [logoMoveRange,setLogoMoveRange] = useState(0)
    const [logoOffset,setLogoOffset] = useState(0)

    const LogoRef = useRef(null)
    const svgRef = useRef([])
    const frontLogoRef = useRef(null)
    const startRef = useRef(null)

    const [logoOpacityTwo,setLogoOpacityTwo] = useState(false)
    const [pinTwo,setPinTwo] = useState(false)
    const [logoColorTwo,setLogoColorTwo] = useState("dark")
    const [logoMoveRangeTwo,setLogoMoveRangeTwo] = useState(0)
    const [logoOffsetTwo,setLogoOffsetTwo] = useState(0)


    const LogoTwoRef = useRef(null)
    const svgTwoRef = useRef([])
    const frontTwoLogoRef = useRef(null)
    const startTwoRef = useRef(null)

    const aboutSectionRef = useRef(null)
    const serviceSectionRef = useRef(null)
    const infiniteSectionRef = useRef(null)
    const brandsSectionRef = useRef(null)
    const staticsSectionRef = useRef(null)
    const teamSectionRef = useRef(null)
    const contactSectionRef = useRef(null)
    const copyRightRef = useRef(null)



    const getResponsive = ()=>{
        const responsive = {
            "lg":1024,
            "sm":640
        }

        return responsive;
    }

    const getWindowWidth = () => {
        if (typeof window !== `undefined`){
            if(window.innerWidth > 1366){
                return 1366
            }else{
                return window.innerWidth
            }
        }
        return 0;
    }

    const getfixerRange = () => {
        if (typeof window !== `undefined`){
            if(window.innerWidth > 1366){
                return (1366 - window.innerWidth) /2
            }else{
                return 0
            }
        }
        return 0;
    }

    const resizeHanlder = ()=>{
        setWindowW(window.innerWidth);
    }

    useEffect(()=>{
        window.addEventListener('resize', resizeHanlder)
        resizeHanlder()
        return () => window.removeEventListener('resize',resizeHanlder);
    })

    const scrollHanlder = ()=>{
        setTrigger()
    }

    useEffect(()=>{
        scrollHanlder()
        window.addEventListener("scroll",scrollHanlder)
        return () => window.removeEventListener("scroll",scrollHanlder)
    })

    useEffect(()=>{

        const defaultLogoSize = [200,50];
        const responsive =getResponsive();
        const windowInnerWidth =  window.innerWidth;
        if(windowInnerWidth >= responsive.lg){
            setLogoSize([400,150])
            setTriggerOffset(-400)
        }else if (windowInnerWidth >= responsive.sm){
            setLogoSize([350,100])
            setLogoOffset(100)
            setTriggerOffset(-400)
            setLogoOffsetTwo(100)
        }else{
            setLogoSize(defaultLogoSize)
        }

    },[windowW])

    //  index ==== mobile logo 腳本
    const setTrigger = () =>{

        if(typeof window === "undefined") return;

        // const windowH = getWindowHeight();
        const windowW = getWindowWidth();
        const responsive =getResponsive();
        const offset = triggerOffset;
        const fixTrigger = 0 - offset;

        const windowInnerWidth =  window.innerWidth;
        // const logoHeight = svgRef.current[0].getBoundingClientRect().height;
        // const parkingTrigger = infiniteSectionRef.current.getBoundingClientRect().y + infiniteSectionRef.current.getBoundingClientRect().height/2 - logoHeight - 96
        if(!copyRightRef.current || !serviceSectionRef.current || !aboutSectionRef.current){
            return ;
        }


        if(windowInnerWidth >= responsive.lg){

            const moveRange = (windowW - logoSize[0]) / 2 - 96;

            setPin(true);

            if(copyRightRef.current.getBoundingClientRect().y <= fixTrigger){
                console.log('copyright')
                setLogoColorTwo("dark")
                setLogoOpacityTwo(1)
                setPinTwo(true)
            }else if( contactSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                console.log('contact')
                setLogoMoveRangeTwo(moveRange)
                setLogoColorTwo("dark")
                setLogoOpacityTwo(1)
                setPinTwo(true)
            }else if( teamSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                console.log('team')
                setLogoMoveRangeTwo(0)
                setLogoColorTwo("light")
                setLogoOpacityTwo(.4)
                setPinTwo(true)
            }else if( staticsSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                console.log('statics')
                setLogoMoveRangeTwo(moveRange)
                setLogoColorTwo("dark")
                setLogoOpacityTwo(1)
                setPinTwo(true)
            }else if( startTwoRef.current.getBoundingClientRect().y <= 0 ){
                console.log('brands')
                console.log(startTwoRef.current.getBoundingClientRect().y)
                setLogoMoveRangeTwo(-moveRange)
                setLogoColorTwo("light")
                setLogoOpacityTwo(1)
                setPinTwo(true)
            }else{
                const logoCenterPosFixer = getfixerRange()
                setLogoMoveRangeTwo(logoCenterPosFixer)
                setLogoColorTwo("dark")
                setLogoOpacityTwo(1)
                setPinTwo(false)
            }

            // logo one =========================================

            if(infiniteSectionRef.current.getBoundingClientRect().y <= fixTrigger + 300 ){
                console.log("infinite")
                setLogoOpacity(0)
            }else if( serviceSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                console.log('service')
                setLogoMoveRange(moveRange)
                setLogoColor("dark")
                setLogoOpacity(1)

            }else if( aboutSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                setLogoMoveRange(-moveRange)
                setLogoColor("light")
                setLogoOpacity(1)

            }else{
                setLogoMoveRange(0)
                setLogoColor("dark")
                setLogoOpacity(.4)
            }

        }else if(windowInnerWidth >= responsive.sm){

            const moveRange = (windowW - logoSize[0]) / 2 - 96;

            if(copyRightRef.current.getBoundingClientRect().y <= fixTrigger){
                console.log('copyright')
                setLogoColorTwo("dark")
                setLogoOpacityTwo(1)
                setPinTwo(true)
            }else if( contactSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                console.log('contact')
                setLogoMoveRangeTwo(moveRange)
                setLogoColorTwo("dark")
                setLogoOpacityTwo(.15)
                setPinTwo(true)
            }else if( teamSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                console.log('team')
                setLogoMoveRangeTwo(moveRange)
                setLogoColorTwo("light")
                setLogoOpacityTwo(.15)
                setPinTwo(true)
            }else if( staticsSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                console.log('statics')
                setLogoMoveRangeTwo(moveRange)
                setLogoColorTwo("dark")
                setLogoOpacityTwo(.15)
                setPinTwo(true)
            }else if( startTwoRef.current.getBoundingClientRect().y <= 0 ){
                console.log('brands')
                setLogoMoveRangeTwo(-moveRange)
                setLogoColorTwo("light")
                setLogoOpacityTwo(.15)
                setPinTwo(true)
            }else{

                setLogoMoveRangeTwo(0)
                setLogoColorTwo("dark")
                setLogoOpacityTwo(1)
                setPinTwo(false)
            }

            // logo one =========================================

            if(infiniteSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                console.log("infinite")
                setPin(false)
                setLogoOpacity(0);
            }else if( serviceSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                console.log('service')
                setLogoMoveRange(moveRange)
                setLogoColor("dark")
                setLogoOpacity(.15);
                setPin(true)

            }else if( aboutSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                setLogoMoveRange(-moveRange)
                setLogoColor("light")
                setLogoOpacity(.15);
                setPin(true)

            }else if(startRef.current.getBoundingClientRect().y <= 0){
                setLogoColor("dark")
                setPin(true)
            }else{
                setLogoMoveRange(0)
                setLogoColor("dark")
                setLogoOpacity(1);
                setPin(false)
            }

        }else{

            setLogoMoveRange(0)

            if(copyRightRef.current.getBoundingClientRect().y <= fixTrigger){
                console.log('copyright')
                setLogoColorTwo("dark")
                setLogoOpacityTwo(1)
                setPinTwo(true)
            }else if( contactSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                console.log('contact')
                setLogoColorTwo("dark")
                setLogoOpacityTwo(.15)
                setPinTwo(true)
            }else if( teamSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                console.log('team')
                setLogoColorTwo("light")
                setLogoOpacityTwo(.15)
                setPinTwo(true)
            }else if( staticsSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                console.log('statics')
                setLogoColorTwo("dark")
                setLogoOpacityTwo(.15)
                setPinTwo(true)
            }else if( startTwoRef.current.getBoundingClientRect().y <= 0 ){
                console.log('brands')
                setLogoColorTwo("light")
                setLogoOpacityTwo(.15)
                setPinTwo(true)
            }else{
                setLogoColorTwo("dark")
                setLogoOpacityTwo(1)
                setPinTwo(false)

            }

            // logo one =========================================

            if(infiniteSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                console.log("infinite")
                setPin(false)
                setLogoOpacity(0);
            }else if( serviceSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                console.log('service')
                setLogoColor("dark")
                setLogoOpacity(.15);
                setPin(true)

            }else if( aboutSectionRef.current.getBoundingClientRect().y <= fixTrigger ){
                setLogoColor("light")
                setLogoOpacity(.15);
                setPin(true)

            }else if(startRef.current.getBoundingClientRect().y <= 0){
                setLogoColor("dark")
                setPin(true)
            }else{
                setLogoColor("dark")
                setLogoOpacity(1);
                setPin(false)

            }

        }



    }

    const myStRef = useRef(null)
    const triggerRef = useRef(null)

    const TransEffect = () => {

        if (typeof window == `undefined` ){
            return;
        }

        const windowW = window.innerWidth;
        const windowH = window.innerHeight;
        const markers = false;

        // console.log(windowH,windowW)

        const fixedLength = windowH > windowW ? windowH : windowW;

        myStRef.current = gsap.timeline()
            .fromTo(".target-1",{width:0,height:0},{
                duration: 1,
                width: fixedLength,
                height: fixedLength,
                scrollTrigger: {
                    trigger: triggerRef.current,
                    start: "top top",
                    end: "+=400px",
                    // pin:true,
                    // pinSpacing: false,
                    scrub: true,
                    markers: markers,
                    id: "scrub"
                }
            }).fromTo(".target-2", {width:0,height:0},{
                duration: 1,
                width:fixedLength,
                height:fixedLength,
                scrollTrigger: {
                    trigger: triggerRef.current,
                    start: "100px",
                    end: "+=400px",
                    // pin:true,
                    // pinSpacing: false,
                    scrub: true,
                    markers: markers,
                    id: "scrub2"
                }
            }).fromTo(".target-3", {width:0,height:0},{
                duration: 1,
                width:fixedLength,
                height:fixedLength,
                scrollTrigger: {
                    trigger: triggerRef.current,
                    start: "200px",
                    end: "+=400px",
                    // pin:true,
                    // pinSpacing: false,
                    scrub: true,
                    markers: markers,
                    id: "scrub3"
                }
            }).fromTo(".target-4", {width:0,height:0},{
                duration: 1,
                width:fixedLength,
                height:fixedLength,
                scrollTrigger: {
                    trigger: triggerRef.current,
                    start: "300px",
                    end: "+=400px",
                    // pin:true,
                    // pinSpacing: false,
                    scrub: true,
                    markers: markers,
                    id: "scrub4"
                }
            }).fromTo(".target-5", {width:0,height:0},{
                duration: 1,
                width:fixedLength,
                height:fixedLength,
                scrollTrigger: {
                    trigger: triggerRef.current,
                    start: "400px",
                    end: "+=400px",
                    // pin:true,
                    // pinSpacing: false,
                    scrub: true,
                    markers: markers,
                    id: "scrub5"
                }
            }).fromTo(".target-6", {opacity:0,scale:.5},{
                duration: 1,
                opacity:1,
                scale:1,
                scrollTrigger: {
                    trigger: ".target-6",
                    start: "-500px",
                    end: "+=200px",
                    // pin:true,
                    // pinSpacing: false,
                    scrub: true,
                    markers: markers,
                    id: "scrub6"
                }
            })
    }

    useEffect(()=>{
        TransEffect()
        return ()=>{
            // if(myStRef.current){
            //     myStRef.current.kill()
            //     myStRef.current.clear()
            //     myStRef.current = null;
            // }
        }
    },[triggerRef])

    return (
        <div className="w-screen overflow-hidden">
            <SEO/>
            <SingleMemberModal member={currentMember} setMember={setCurrentMember} />
            <MembersModal members={members} show={displayMembers} setShow={setDisplayMember}/>
            <CanvasGridBg w={150} h={150} bgSpeed={.1} stroke={"rgba(158,160,163,.27)"}/>

            {/*  index */}
            <FullScreenSection
                className="bg-dark py-24 lg:flex lg:items-center lg:justify-center"
            >
                {/*start pin trigger*/}
                <div id="start" ref={startRef}/>
                <div className="relative z-30">
                    <OnScreenTypewriter Tag="h1" text="010 TECH" className="text-6xl text-white text-center font-share block sm:text-144 lg:text-176"/>
                    <h3 className="text-white text-center text-3xl text-center font-bold mt-6 sm:text-42 lg:text-5xl">我們打造更多人使用的專業服務！</h3>
                </div>
                <LogoWithFilter moveRange={logoMoveRange} Top={logoOffset} svgRef={svgRef} Opacity={logoOpacity} logoColor={logoColor} logoSize={logoSize[0]} depth={logoSize[1]} LogoRef={LogoRef} fixed={pin} containerRef={frontLogoRef} />
            </FullScreenSection>
            <FullScreenSection
                sectionRef={aboutSectionRef}
                className="py-24 flex items-center justify-center"
            >
                <div className="sm:flex sm:justify-end">
                    <div className="sm:w-3/4 lg:w-1/2">
                        <OnScreenTypewriter
                            text="About"
                            className="text-6xl text-right font-share block hollow dark sm:text-144"/>
                        <OnScreenTypewriter
                            Tag="h3"
                            text="零一零科技攜手專業人士共創成就，打造專業領域的頂尖品牌，並導入科技能量，優化企業管理效率及提高產能！"
                            className="text-gray-010 text-center text-xl text-right font-bold mt-6 sm:text-28"
                            speed={50}
                            label={false}
                        />
                    </div>
                </div>

            </FullScreenSection>

            <FullScreenSection
                className="bg-dark py-24"
                sectionRef={serviceSectionRef}
            >
                <div className="sm:flex sm:justify-start">
                    <div className="sm:w-3/4 lg:w-1/2">
                        <OnScreenTypewriter
                            text="Service"
                            className="text-6xl text-white text-center font-share block hollow sm:text-144 sm:text-left"
                        />
                        <OnScreenTypewriter
                            Tag="h3"
                            text="零一零科技提供客戶垂直整合的解決方案，完整發揮產業價值鏈的潛力！"
                            className="text-white text-center text-xl font-bold mt-6 sm:text-28 sm:text-left"
                            speed={50}
                            label={false}
                        />
                        <div className="mt-16">
                            <div>
                                {
                                    services.map(({title,description,icon},index)=>{
                                        return(
                                            <ServiceBlock key={`service_${index}`} Icon={icon.svg.content} title={title} description={description.description}/>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </FullScreenSection>


            <div ref={infiniteSectionRef}/>

            <div ref={triggerRef} className="w-screen relative" style={{height:'100vh'}}>
                <div className="flex items-center justify-center fixed w-screen h-screen top-0 left-0 z-0">
                    <div className="bg-dark target-1"></div>
                </div>
                <div className="flex items-center justify-center fixed w-screen h-screen top-0 left-0 z-0">
                    <div className="bg-white target-2"></div>
                </div>
                <div className="flex items-center justify-center fixed w-screen h-screen top-0 left-0 z-0">
                    <div className="bg-dark target-3"></div>
                </div>
                <div className="flex items-center justify-center fixed w-screen h-screen top-0 left-0 z-0">
                    <div className="bg-white target-4"></div>
                </div>
                <div className="flex items-center justify-center fixed w-screen h-screen top-0 left-0 z-0 border-t-2 border-white">
                    <div className="bg-dark target-5"></div>
                </div>
            </div>
            <FullScreenSection
                className="py-24 relative"
                // sectionRef={infiniteSectionRef}
            >
                <div>
                    <h2 className="target-6 text-4xl text-white text-center font-black block hollow sm:text-96 leading-normal">
                        專業無限放大的可能 <br/>1＋1＝∞
                    </h2>
                    <div className="mt-6"/>
                    <h3 className="text-white text-left font-bold sm:text-28 target-6">
                        我們了解專業的價值，協助讓更多人使用專業服務是零一零的使命。我們將專業轉譯成民眾更容易理解的內容，並精準投放於目標受眾喚起使用需求，創造正向循環並達成更優質的商業模式。
                    </h3>
                    <div className="absolute top-0 left-0 mt-10 sm:mt-64">
                        <div ref={startTwoRef} className="absolute top-0"/>
                        <LogoWithFilter moveRange={logoMoveRangeTwo} Top={logoOffsetTwo} svgRef={svgTwoRef} Opacity={logoOpacityTwo} logoColor={logoColorTwo} logoSize={logoSize[0]} depth={logoSize[1]} LogoRef={LogoTwoRef} fixed={pinTwo} containerRef={frontTwoLogoRef} />
                    </div>
                    <div className="h-60vh hidden sm:block lg:h-80vh"/>
                </div>
            </FullScreenSection>

            <FullScreenSection
                className="py-24 bg-white relative"
                sectionRef={brandsSectionRef}
            >
                <div className="sm:flex sm:justify-end">
                    <div className="sm:w-3/4 lg:w-1/2">
                        <OnScreenTypewriter
                            text="BRANDS"
                            className="text-6xl text-center font-share block hollow dark sm:text-144 sm:text-right"/>
                        <div className="mt-16 sm:pr-12 lg:pr-0">
                            <div>
                                {
                                    brands.map(({title,description,icon,link},index)=>{
                                        const nolink = "";
                                        return(
                                            <BrandBlock key={`brand_${index}`} link={nolink} title={title} description={description.description} Icon={icon.svg.content}/>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </FullScreenSection>

            <FullScreenSection
                className="bg-dark py-24 relative"
                sectionRef={staticsSectionRef}
            >
                <div className="sm:flex sm:justify-start">
                    <div className="sm:w-3/4 lg:w-1/2">
                        <OnScreenTypewriter
                            text="Statistics"
                            className="text-6xl text-center font-share block hollow sm:text-144 sm:text-left"/>
                        <div className="mt-12">
                            {
                                statistics.map(({title,unit,number},index)=>
                                    <StaticBlock key={`statistic_${index}`} number={number} unit={unit} description={title}/>
                                )
                            }
                        </div>
                    </div>

                </div>
            </FullScreenSection>

            <FullScreenSection
                className="py-24 bg-white relative"
                sectionRef={teamSectionRef}
            >
                <div className="sm:flex sm:justify-start">
                    <div className="sm:w-3/4 lg:w-full lg:flex lg:flex-wrap">
                        <div className="lg:w-2/3">
                            <OnScreenTypewriter
                                text="Team"
                                className="text-6xl text-center font-share block hollow dark sm:text-144 sm:text-left"/>
                            <OnScreenTypewriter
                                Tag="h3"
                                text="零一零科技是由專業的律師、工程師及設計師組成，活用科技工具與分析規劃能力，深入剖析各種專業服務領域，建立高效率的標準流程，協助客戶提供民眾更好的服務品質，並取得業績及規模的增長。零一零科技的核心理念是『讓更多人使用專業的服務』，就讓我們一起讓更多人相信專業服務並共創價值！ "
                                className="text-gray-010 text-left mt-6 sm:text-xl"
                                speed={50}
                                label={false}
                            />
                        </div>
                        <div className="mt-12 sm:mt-24 lg:w-full lg:relative lg:pr-32">
                            <div className="sm:grid sm:grid-cols-2 sm:gap-10 lg:grid-cols-4 lg:gap-0">
                                {
                                    members.slice(0, 4).map((member,index)=>{
                                        const {title,name,image} = member
                                        return (
                                            <MemberBlock key={`member_${index}`} onClick={()=>setCurrentMember(member)} image={image} imagePath="img_wen.jpg" name={name} title={title}/>
                                        )
                                    })
                                }
                            </div>
                            <div className="lg:absolute lg:w-32 lg:h-full lg:right-0 lg:top-0 lg:flex lg:items-center lg:justify-end">
                                <div onClick={()=>{setDisplayMember(true)}} tabIndex={-1} role="button" onKeyDown={()=>{}} className="transition-all lg:duration-200 text-xl font-share leading-tight text-gray-010 w-20 h-10 flex items-center justify-center border border-gray-010 mx-auto cursor-pointer hover:bg-gray-010 hover:text-white">
                                    More
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </FullScreenSection>

            <FullScreenSection
                className="bg-dark pt-24 pb-12 relative"
                sectionRef={contactSectionRef}
            >
                <div className="sm:flex">
                    <div className="sm:w-3/4 lg:w-1/2">
                        <OnScreenTypewriter
                            text="Contact"
                            className="text-6xl text-center font-share block hollow sm:text-144 sm:text-left"/>
                        <div className="mt-12">
                            <ContactForm/>
                        </div>
                    </div>
                </div>
            </FullScreenSection>

            <div className="bg-dark pt-24 pb-12 px-6 sm:pt-0 relative z-0" ref={copyRightRef}>
                <div className="relative h-60vh sm:h-auto flex flex-col justify-end sm:h-auto sm:mt-0 sm:flex-row sm:justify-start lg:max-w-1366 lg:mx-auto lg:px-6 z-20">
                    <Logo010 className="h-auto w-8 mx-auto sm:mr-3 sm:ml-0" />
                    <div className="text-center text-white mt-4 sm:text-xl">
                        © 2020 零一零科技股份有限公司 All Rights Reserved.
                    </div>
                </div>
            </div>

        </div>
    )
}
