import React,{useEffect,useReducer} from "react";
import CanvasHoverLine from "./canvas-hover-line";
const initialFixedSvg = "";

export default ({Icon,title,description,link=""})=>{

    const reducer = (state, action) => {
        return  SVGfixer(Icon)
        // switch (action) {
        //     case 'increment': return "inc";
        //     case 'decrement': return "dec";
        //     case 'reset': return "rest";
        //     default: return state;
        // }
    };

    const SVGfixer =(Icon)=> {
        const reanderIcon = document.createElement("div")
        reanderIcon.innerHTML = Icon
        const targetSvg = reanderIcon.querySelector("svg")
        targetSvg.classList.add("w-full","h-auto","lg:group-hover:fill-white","lg:transition-all-200")
        return targetSvg.outerHTML
    }

    const [fixedSvg, dispatch] = useReducer(reducer, initialFixedSvg);

    useEffect(()=>{
        dispatch()
    })

    // const [fixedSvg,setFixedSvg] = useState("")
    //
    // useEffect(()=>{
    //     if(typeof window !== "undefined" ){
    //         setFixedSvg(SVGfixer(Icon))
    //     }
    // },[])

    return (
        <a
            href={link ? link : ""}
            target={link ? "_blank" : "_self"}
            onClick={(e)=>{
                if(!link){
                    e.preventDefault()
                }
            }}
            className="block mb-12 sm:flex group relative cursor-pointer">
            <div className="relative w-42 h-42 mx-auto sm:order-2 sm:mr-3">
                <div className="transition-all duration-200 border-gray-010 border absolute bottom-0 left-0 w-42 h-42 mb-3 ml-3 lg:group-hover:to-right-top">
                    <CanvasHoverLine className="absolute top-0 right-0 transition-all duration-200 opacity-0 lg:group-hover:opacity-100" w={18} h={18} strokeStyle={"#000000"} Xfrom="right"/>
                    <CanvasHoverLine className="absolute top-0 right-full transition-all duration-200 opacity-0 lg:group-hover:opacity-100" style={{top:"-1px"}} w={18} h={18} strokeStyle={"#000000"} Xfrom="right"/>
                    <CanvasHoverLine className="absolute top-full right-0 transition-all duration-200 opacity-0 lg:group-hover:opacity-100" style={{right:"-1px"}} w={18} h={18} strokeStyle={"#000000"} Xfrom="right"/>
                </div>
                <div className="transition-all duration-200 border-gray-010 border absolute top-0 left-0 w-42 h-42 justify-center items-center flex lg:group-hover:bg-gray-010 lg:group-hover:to-left-bottom" dangerouslySetInnerHTML={{ __html: fixedSvg }}>
                    {/*<Icon className="h-auto lg:group-hover:fill-white lg:transition-all-200"/>*/}
                </div>
            </div>
            <div className="sm:order-1 sm:mr-6">
                <h3 className="text-xl font-bold text-gray-010 text-center mt-3 sm:text-2xl sm:text-right">{title}</h3>
                <p className="text-gray-010 text-center mt-3 sm:text-xl sm:text-right">{description}</p>
            </div>
            <div className="transition-all duration-200 group-hover:delay-300 group-hover:opacity-100 opacity-0 hidden absolute border-b-2 border-gray-010 bottom-0 mb-2 left-full h-1 lg:block right-42 z-0 lg:group-hover:left-0"></div>
        </a>
    )
}