import React,{useState,useEffect,useRef} from "react";
import "../styles/typewriter.css";

const Typewriter = ({text="",speed=200,className="",label=true,holdplace=false}) => {

    const [textIndex,setTextIndex] = useState(0);
    const [display,setDisplay] = useState("");
    const typeRef = useRef(null);

    // return(
    //     <span ref={typeRef} className={className + " block relative"}>
    //         {text}
    //     </span>
    // )

    useEffect(() => {

        const typing = ()=>{

            if(typeRef.current === null) return;

            if(text === ""){
                setTextIndex(0);
                setDisplay("");
            }

            if (textIndex < text.length) {
                const _display = display + text.charAt(textIndex);
                setTextIndex(textIndex + 1);
                setDisplay(_display);
            }

        }

        const timer = setTimeout(typing, speed);
        return () => clearTimeout(timer);

    }, [text,typeRef,display,speed,textIndex]);

    return (
        <>
            {holdplace ?
                <span ref={typeRef} className={className + " block relative"}>
                    <span className="opacity-0">{text}</span>
                    <span className="absolute inset-0">
                        {display}
                        {label ?
                            <span className="typewriter-dash">_</span> : ""
                        }
                    </span>
                </span>
                :
                <span ref={typeRef} className={className + " block"}>
                {display}
                    {label ?
                        <span className="typewriter-dash">_</span> : ""
                    }
                </span>
            }

        </>
    )
}

export default Typewriter;