// import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const MemberHook = () => {
    const data = useStaticQuery(graphql`
    {
      allContentfulTeamMember(sort: {fields: order, order: ASC}) {
        nodes {
          title
          name
          description {
            description
          }
          image {
             fixed(cropFocus: TOP, height: 300, width: 300){
              ...GatsbyContentfulFixed_withWebp
            }
            members:fixed(cropFocus: TOP, height: 512, width: 512){
              ...GatsbyContentfulFixed_withWebp
            }
            inner:fixed(quality: 10, height: 1440, width: 1080) {
                ...GatsbyContentfulFixed_withWebp
            }
            fluid(quality: 10, resizingBehavior: FILL, cropFocus: TOP, maxHeight: 400, maxWidth: 300){
                ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)
    return data.allContentfulTeamMember.nodes
}

export default MemberHook